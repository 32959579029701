// 集团简介
<template>
  <div class="company-groupProfile">
    <Info class="groupProfile-info" />
  </div>
</template>

<script>
import Info from '@/components/Info'
export default {
  name: 'CompanyGroupProfile',
  components: {
    Info
  },
};
</script>

<style scoped lang="less">
.groupProfile-info {
  /deep/ p {
    line-height: 33px;
    span {
      font-size: 16px !important;
    }
  }
}
</style>
