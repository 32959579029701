<template>
  <div v-html="info"></div>
</template>

<script>
import { mapGetters } from "vuex"
import { getInfo } from '@/api'
export default {
  name: 'Info',
  props: {
    isData: {
      type: Array,
      default: () => { return [] }
    }
  },
  data () {
    return {
      info: ''
    };
  },
  computed: {
    ...mapGetters(['getNavItem'])
  },

  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      if (this.getNavItem) {
        if (this.isData.length) {
          var data = this.isData
        } else {
          var { data } = await getInfo(this.getNavItem.id)
          this.setData(data)
        }
        this.info = data[0].text
      }
    },
    setData (data) {
      this.$emit('setData', data)
    }
  },
};
</script>

<style scoped lang="less">
</style>
